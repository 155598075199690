import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { FormControl, MenuItem, Select, ListSubheader, Button } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import "react-pdf/dist/esm/Page/TextLayer.css";

if (typeof Promise.withResolvers === 'undefined') {
  Promise.withResolvers = function () {
    let resolve, reject
    const promise = new Promise((res, rej) => {
      resolve = res
      reject = rej
    })
    return { promise, resolve, reject }
  }
}

// Ensure pdfjs worker is loaded from the CDN
pdfjs.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@4.3.136/legacy/build/pdf.worker.min.mjs';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  viewer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    overflow: 'auto',
  },
  iframe: {
    width: '100%',
    height: '90vh', // Adjusted height to fit within the viewport
    border: 'none',
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  pageControls: {
    display: 'flex',
    alignItems: 'center',
  },
  menuPaper: {
    maxHeight: 48 * 5 + 8,
  },
  listHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none', // Disable interaction with the arrows
  },
  navigation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  pageButton: {
    margin: theme.spacing(1),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
    },
  },
};

const pdfFiles = {
  latest: { url: 'https://storage.chicagoariranglionsclub.com/booklet%2F2024_picinc_01152025.pdf', name: '2024' },
  picnic2023: { url: 'https://storage.chicagoariranglionsclub.com/booklet%2F2023_picnic.pdf', name: '2023' },
  picnic2019: { url: 'https://storage.chicagoariranglionsclub.com/booklet%2F2019_picnic.pdf', name: '2019' },
  picnic2018: { url: 'https://storage.chicagoariranglionsclub.com/booklet%2F2018_picnic.pdf', name: '2018' },
  picnic2017: { url: 'https://storage.chicagoariranglionsclub.com/booklet%2F2017_picnic.pdf', name: '2017' },
  picnic2014: { url: 'https://storage.chicagoariranglionsclub.com/booklet%2F2014_picnic.pdf', name: '2014' },
  picnic2013: { url: 'https://storage.chicagoariranglionsclub.com/booklet%2F2013_picnic.pdf', name: '2013' },
  picnic2012: { url: 'https://storage.chicagoariranglionsclub.com/booklet%2F2012_picnic.pdf', name: '2012' },
  picnic2011: { url: 'https://storage.chicagoariranglionsclub.com/booklet%2F2011_picnic.pdf', name: '2011' },
  picnic2010: { url: 'https://storage.chicagoariranglionsclub.com/booklet%2F2010_picnic.pdf', name: '2010' }
};

export default function BookletPage() {
  const styles = useStyles();
  const containerRef = useRef(null);
  const [selectedPdf, setSelectedPdf] = useState(pdfFiles.latest.url);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageWidth, setPageWidth] = useState(0);

  const isMobile = window.matchMedia('(max-width: 600px)').matches;

  const handlePdfChange = (event) => {
    setSelectedPdf(event.target.value);
    setPageNumber(1); // Reset to the first page when a new PDF is selected
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    const updatePageWidth = () => {
      if (containerRef.current) {
        setPageWidth(containerRef.current.clientWidth * 0.9);
      }
    };

    updatePageWidth();
    window.addEventListener('resize', updatePageWidth);

    return () => {
      window.removeEventListener('resize', updatePageWidth);
      if (pdfjs.GlobalWorkerOptions.workerPort) {
        pdfjs.GlobalWorkerOptions.workerPort.close();
      }
    };
  }, [selectedPdf]);

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <div className={styles.heroContent}>
          <Container maxWidth="lg" ref={containerRef}>
            <div className={styles.controls}>
              <FormControl className={styles.formControl}>
                <Select
                  value={selectedPdf}
                  onChange={handlePdfChange}
                  MenuProps={MenuProps}
                  renderValue={() => `${pdfFiles[Object.keys(pdfFiles).find(key => pdfFiles[key].url === selectedPdf)].name}`}
                >
                  <ListSubheader className={styles.listHeader}>
                    <ArrowDropUpIcon />
                  </ListSubheader>
                  {Object.keys(pdfFiles).map((key) => (
                    <MenuItem key={key} value={pdfFiles[key].url}>
                      {pdfFiles[key].name}
                    </MenuItem>
                  ))}
                  <ListSubheader className={styles.listHeader}>
                    <ArrowDropDownIcon />
                  </ListSubheader>
                </Select>
              </FormControl>
              {isMobile && (
                <div className={styles.pageControls}>
                  <Button className={styles.pageButton} onClick={handlePreviousPage} disabled={pageNumber <= 1}>Previous</Button>
                  <span>{pageNumber} of {numPages}</span>
                  <Button className={styles.pageButton} onClick={handleNextPage} disabled={pageNumber >= numPages}>Next</Button>
                </div>
              )}
            </div>
            <div className={styles.viewer}>
              {isMobile ? (
                <Document
                  file={selectedPdf}
                  onLoadSuccess={onDocumentLoadSuccess}
                  error="Error while loading document!"
                >
                  <Page pageNumber={pageNumber} width={pageWidth} renderTextLayer={false} renderAnnotationLayer={false} />
                </Document>
              ) : (
                <iframe
                  src={selectedPdf}
                  className={styles.iframe}
                  title="PDF Viewer"
                />
              )}
            </div>
          </Container>
        </div>
      </main>
    </React.Fragment>
  );
}
