import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardGrid: {
    paddingBottom: theme.spacing(8),
  },
  card: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
    boxShadow: theme.shadows[3],
  },
  cardContent: {
    textAlign: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Optional background for readability
  },
  cardMedia: {
    position: 'relative',
    paddingTop: '56.25%', // 9:16 aspect ratio
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
}));

const PREFIX_HREF = "/events?id=";
const PREFIX_IMAGE = "https://storage.chicagoariranglionsclub.com/event/";

export default function PastEventPreview() {
  const classes = useStyles();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    axios
      .get('https://common.chicagoariranglionsclub.com/api/event/past')
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error('Error fetching events:', error);
      });
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Past Events
            </Typography>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="lg">
          <Grid container spacing={4}>
            {events.map((event) => (
              <Grid item key={event.id} xs={12} sm={12} md={6}>
                <Link to={`${PREFIX_HREF}${event.id}`}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      style={{
                        backgroundImage: `url(${PREFIX_IMAGE}${event.id}/preview.jpg)`
                      }}
                      title={event.title}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {event.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </React.Fragment>
  );
}
