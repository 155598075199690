import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  videoGrid: {
    padding: theme.spacing(4, 0),
  },
  videoItem: {
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%', // 16:9 aspect ratio
    overflow: 'hidden',
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0,
  },
}));

export default function VideoPreview() {
  const styles = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <div className={styles.heroContent}>
          <Container maxWidth="sm">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Activities
            </Typography>
          </Container>
        </div>
        <Container maxWidth="lg" className={styles.videoGrid}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} lg={6}>
              <div className={styles.videoItem}>
                <iframe
                  className={styles.iframe}
                  src="https://www.youtube.com/embed/7w5Xu1mv6gY"
                  title="CALC"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className={styles.videoItem}>
                <iframe
                  className={styles.iframe}
                  src="https://www.youtube.com/embed/Vf3Qkd-wWaI"
                  title="14th KCT"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </Grid>
          </Grid>
        </Container>
      </main>
    </React.Fragment>
  );
}
